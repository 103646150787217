@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

:root {
    --primary: #0C3553;
    --primary_01: rgba(12, 53, 83, 0.1);
    --primary_50: rgba(12, 53, 83, 0.5);
    --primary_0_8: rgba(12, 53, 83, 0.08);
    --primary_0_15: rgba(12, 53, 83, 0.15);
    --primary_0_05: rgba(12, 53, 83, 0.05);
    --primary_0_20: rgba(12, 53, 83, 0.20);
    --success: #43DDA1;
    --danger: #FF3D00;
    --active: #3CB3C1;
    --green: #34C759;
    --black: #393939;
    --read: #F0F5FF;
}


html {
    /* scrollbar-width: thin;
    scrollbar-color: var(--primary) var(--primary_01); */
}

body {
    font-family: 'Lato', sans-serif !important;
    background-color: var(--bs-body-bg) !important;
}

.text-success {
    color: var(--success) !important;
}

p {
    margin-bottom: 0;
}

h2 {
    line-height: normal !important;
}

.danger_color {
    color: var(--danger);
}

.g_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-check-input:checked {
    background-color: var(--green) !important;
    border-color: var(--green) !important;
    /* border-radius: 100px !important; */
}



.form-switch-md .form-check-input {
    /* width: 51px !important;
    height: 31px !important; */
}

.rtl {
    direction: rtl;
}

.g_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.g_end {
    display: flex;
    align-items: center;
    justify-content: end;
}
.g_start {
    display: flex;
    align-items: center;
    justify-content: start;
}

.h_center {
    display: block;
    margin: auto;
}

.h_100 {
    height: 100vh;
}

.b_r_12,
.b_r_12 .css-13cymwt-control {
    border-radius: 12px !important;
}

.h_46 .css-13cymwt-control,
.h_46 .css-t3ipsp-control {
    min-height: 46px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.form_field {
    margin: 10px 0;
}

.form_field label {
    display: block;
    margin-bottom: 5px;
    padding-top: 0;
}

.danger_color_border,
.danger_color_border .css-13cymwt-control {
    border-color: #f46a6a !important;
    border: 1px solid;
    border-radius: 12px;
}


/* .h_48.css-b62m3t-container .css-13cymwt-control {
    height: 48px !important;
} */

.full_height_card {
    height: calc(100% - 24px) !important;
}

.full_th th {
    white-space: nowrap;
}

.form_field input,
.form_field input:focus,
.form_field textarea,
.form_field select,
.form_field select:focus {
    width: 100%;
    border: 1px solid var(--primary_0_8);
    border-radius: 8px;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    height: 46px;
    background-color: var(--primary_0_05);
    color: var(--primary);
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    /* appearance: auto !important; */
}

input[readonly]:not(.flatpickr-input) {
    cursor: not-allowed !important;
}

.form_field input.rtl:focus {
    padding-right: 35px !important;
}

.form_field input#react-select-2-input {
    height: auto !important;
}

.select_wrapper {
    position: relative;
}

.select_wrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    background: url(./assets/images/icons/arrow-down.svg) no-repeat center;
    pointer-events: none;
}

.select_wrapper:has(select.is-invalid)::after {
    right: 22px;
}

.form_field textarea {
    height: auto;
}

.form_field label,
.g_label label {
    color: var(--primary);
    font-size: 15px;
    font-weight: 600;
    line-height: 18.15px;
    margin-bottom: 10px;
}

.card {
    border-radius: 12px !important;
}



/* TABLE START */
table button {
    padding: 5px 10px !important;
}

table td {
    vertical-align: middle;
}

table thead th {
    background: rgba(12, 53, 83, 0.05) !important;
    color: var(--primary) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

table tbody td {
    color: var(--primary) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* text-transform: capitalize; */
}

table tbody td.no_transform {
    text-transform: unset !important;
}

table th:first-child,
table td:first-child {
    padding-left: 28px;
    vertical-align: middle;
    color: var(--primary);
}

table.first_th_p_0 th:first-child,
table.first_th_p_0 td:first-child {
    padding-left: 0;
}

table tbody tr:last-child td,
table tbody tr:last-child th {
    border-radius: 12px;
}

.details_view table th:first-child {
    padding-left: 0;
    color: var(--primary);
}

/* TABLE END */

/* MODAL START*/
.modal-title {
    color: var(--primary);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.modal-header,
.modal-body {
    padding-left: 30px;
    padding-right: 30px;
}

.modal-header {
    border-bottom: none;
}

.w_600 {
    min-width: 800px;
}

.fit_content {
    min-width: fit-content !important;
    white-space: nowrap;
}

.modal-content {
    border-radius: 15px;
}

.modal_footer_button {
    margin-top: 20px;
}

/* MODAL END*/

.card-title {
    color: var(--primary) !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 18.15px;
    text-align: left;

}

.main_title {
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    color: var(--primary);
    margin-bottom: 11px;
}

.sub_title {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: var(--primary_50);
    margin-bottom: 20px;
}

.card_title,
.fs_24 {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    color: var(--primary);
}

.fs_18_700 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    color: var(--primary);
}

.fs_18_400 {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: var(--primary);
}

.fs_14_600 {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    color: var(--primary);
}

.text-red {
    color: red;
}


/* NAV START */
.nav-tabs-custom .nav-item .nav-link::after {
    height: 3px;
}

/* NAV END */

/* BUTTONS START */
.btn_1,
.btn_1:hover {
    background-color: var(--primary_01);
    color: var(--primary);
    border: none;
    height: 30px;
}

.transparent_btn {
    background: #0000;
    border: none;
}

.cancel_btn {
    border: 1px solid var(--primary);
    border-radius: 8px;
    padding: 9px 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 16.8px;
    color: var(--primary);
    min-width: 110px;
    height: 48px;
    background-color: transparent;
}

.primary_btn {
    min-width: 110px;
    height: 48px;
    border: 1px solid var(--primary);
    border-radius: 8px;
    background-color: var(--primary);
    font-size: 16px;
    font-weight: 700;
    line-height: 16.8px;
    color: #FFFFFF;
    padding: 9px 20px;
}

.danger_btn {
    border: 1px solid var(--danger);
    background-color: var(--danger);
}

.primary_btn:disabled,
button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.save_btn,
.create_btn {
    width: 158px;
    height: 50px;
    border-radius: 12px;
    background-color: var(--primary);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #fff;
    border: none;
}

.create_btn {
    width: 149px;
    height: 48px;
    font-size: 16px;
    border-radius: 8px;
}

.upload_btn {
    width: 76px;
    height: 33px;
    border-radius: 8px;
    border: 1px solid var(--primary_0_15);
    background-color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: var(--primary);
}

.pagination_prev_btn {
    width: 82px;
    height: 35px;
    border-radius: 6px;
    border: 1px solid var(--primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: var(--primary);
}

.pagination_next_btn {
    width: 82px;
    height: 35px;
    border-radius: 6px;
    border: 1px solid var(--primary);
    background-color: var(--primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #fff;
    margin-left: 10px;
}

.approve_btn {
    width: 83px;
    height: 33px;
    background: var(--primary);
    border-radius: 8px;
    border: 1px solid var(--primary);
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: #fff;
}

.reject_btn {
    width: 83px;
    height: 33px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #FF3D00;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: #FF3D00;
}

.delete_btn {
    background: #FF3D00;
    border: 1px solid #FF3D00;
}

.export_btn {
    width: 41px !important;
    height: 35px !important;
    background-color: #D5B086 !important;
    border-radius: 8px !important;
    border-color: #D5B086 !important;
    padding: 0 !important;
    display: flex !important;

    i {
        font-size: 26px;
    }
}

/* BUTTONS END */

.sub-menu li:last-child {
    padding-bottom: 5px;
}

/* IMAGE PREVIEW START */
.img-preview {
    max-height: 100%;
    /* object-fit: contain; */
}

.video-preview {
    height: 100%;
    max-height: 100%;
    width: 100%;
    border-radius: 16px !important;
    /* object-fit: contain; */
}


.file_label {
    width: 100%;
    height: 200px;
    border-radius: 16px;
    border: 1px dashed rgba(12, 53, 83, 0.2);
    background: rgba(12, 53, 83, 0.2);
    display: flex !important;
    cursor: pointer;

    .gallery_icon {
        position: absolute;
        top: 35%;
        font-size: 24px;
    }

    .trash_icon {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;
        cursor: pointer;
    }
}

.file_label.disabled,
.trash_icon.disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

/* IMAGE PREVIEW END */

canvas {
    margin: auto
}

/* react select */
#react-select-2-input {
    height: auto !important;
}

.css-1n6sfyn-MenuList {
    max-height: 230px !important;
}

.invalid-feedback {
    font-size: 13px !important;
}

.hr_100 {
    width: 110%;
    position: relative;
    left: -5%;
    margin-bottom: 0;
}

/* DETAILS INNER DIV START */
.details_inner_div {
    margin-top: 20px;

    p {
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        color: var(--primary_50);
        margin-bottom: 5px;
    }

    h2 {
        font-size: 16px;
        font-weight: 700;
        line-height: 19.2px;
        color: var(--primary);
    }


}

.active_color {
    color: var(--active) !important;
}

.pending_color {
    color: #FCB859 !important;
}

.danger_color {
    color: var(--danger) !important;
}


/* DETAILS INNER DIV END */

/* GLOBAL CLASSES START */

.fs_12 {
    color: var(--primary);
    font-size: 12px;
    line-height: 18px;
}

.fs_14_500 {
    color: var(--primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
}

.fs_16 {
    color: var(--primary);
    font-size: 16px;
    line-height: 20px;
}

.fs_18_700 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
}

.fs_20_600 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    color: var(--primary);
}


.fs_20 {
    font-size: 20px;
    line-height: 24px;
    color: var(--primary);
}

.fs_24 {
    font-size: 24px;
    line-height: 29.05px;
    color: var(--primary);
}

.fw_400 {
    font-weight: 400;
}

.fw_500 {
    font-weight: 500;
}

.fw_600 {
    font-weight: 600;
}


.text_black {
    color: var(--black) !important;
}

.set_right {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.reset_btn {
    border-radius: 8px;
    background-color: var(--primary);
    height: 35px;
    width: 40px;
    color: #fff;
    border: none;
    font-size: 24px;
}

.reset_btn.dashboard_reset_btn {
    margin-right: 20px;
    height: unset;

    img {
        padding-bottom: 6px;
    }

}

.un_hold_btn {
    border-radius: 8px;
    background-color: #FFFFFF;
    height: 33px;
    width: 76px;
    color: var(--primary);
    border: 1px solid var(--primary_0_15);
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
}

.hold_btn {
    border-radius: 8px;
    background-color: var(--primary_0_15);
    height: 33px;
    width: 76px;
    color: var(--primary);
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
}

.status_tag {
    width: 72px;
    font-size: 12px;
    font-weight: 500;
    line-height: 12.1px;
    margin-bottom: 0;
    text-align: center;
    padding: 5px;
    border-radius: 4px;
}

.sold_status {
    background-color: rgba(252, 184, 89, 0.1);
    border: 1px solid #FCB859;
    color: #FCB859 !important;
}

.rended_status {
    background-color: rgba(169, 223, 216, 0.1);
    border: 1px solid #A9DFD8;
    color: #A9DFD8 !important;
}

.reserved_status {
    background-color: rgba(40, 174, 243, 0.1);
    border: 1px solid #28AEF3;
    color: #28AEF3 !important;
}

.available_status {
    background-color: rgba(242, 200, 237, 0.1);
    border: 1px solid #F2C8ED;
    color: #F2C8ED !important;
}

.sent_status {
    background-color: rgba(169, 223, 216, 0.12);
    border: 1px solid #A9DFD8;
    color: #A9DFD8;
    width: 80px;
}

.in_progress_status {
    background-color: rgba(40, 174, 243, 0.12);
    border: 1px solid #28AEF3;
    color: #28AEF3;
    width: 80px;
}

.pending_status {
    background-color: rgba(252, 184, 89, 0.12);
    border: 1px solid #FCB859;
    color: #FCB859 !important;
}

.reject_status {
    background-color: rgba(255, 61, 0, 0.12);
    border: 1px solid #FF3D00;
    color: #FF3D00 !important;
}

.open_status {
    background-color: rgba(12, 53, 83, 0.12);
    border: 1px solid #0C3553;
    color: #0C3553 !important;
}

.inprogress_status {
    background-color: rgba(213, 176, 134, 0.12);
    border: 1px solid #D5B086;
    color: #D5B086 !important;
}

.closed_status {
    background-color: rgba(226, 106, 211, 0.12);
    border: 1px solid #E26AD3;
    color: #E26AD3 !important;
}

.nav-link {
    font-size: 15px !important;
    font-weight: 600;
    line-height: 18.15px;
    color: var(--primary) !important;
}

.g_modal .modal-content {
    padding: 30px;
    border-radius: 20px;
}

.modal_title {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    color: var(--primary);
    margin-bottom: 5px;
}

.w_850 {
    min-width: 850px;
}

.w_600 {
    min-width: 600px;
}

.w_149 {
    width: 149px;
}

.h_43 {
    height: 43px;
}

.h_72 {
    height: 72px;
}

.h_46 {
    height: 46px;
}

.b_r_16 {
    border-radius: 16px !important;
}

.modal_close_btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #0000;
    border: none;
}

.five_col {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px
}

.object_fit_contain {
    object-fit: contain;
}

.in_2_line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}


/* GLOBAL CLASSES END */