/* LOGIN START */
.public_header {
    height: 75px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 20px;
}

.h_100_75 {
    height: calc(100vh - 75px);
}

.dashboard_filter_flatpickr {
    top: 152px !important;
    right: 24px !important;
    left: unset !important
}

.box-shadow-header {
    box-shadow: 0 .75rem 1.5rem #12263f08;
}

.dashboard_common_date {
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 14px;

    .calender_icon {
        width: 48px;
        height: 48px;
        background-color: #2D9CDB26;
        border-radius: 12px;
    }

    .filter_period {
        margin-left: 16px;
    }
}

.custom_notification {
    .notification_bar {
        width: 36px;
        height: 36px;
        background-color: #2D9CDB26;
        border-radius: 50%;
        position: relative;

        .notification_count {
            position: absolute;
            top: -7px;
            right: -4px;
            font-size: 8px;
            font-weight: 700;
            line-height: 18px;
            text-align: center;
            color: #FFFFFF;
            background-color: #2D9CDB;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            outline: 4px solid #EFF0F2;
        }
    }

    .notification_inner {
        .unread {
            background: var(--read);
        }

        .avatar {
            background-color: var(--primary_01);
            min-width: 30px;
            height: 30px;
            border-radius: 50%;
        }

        .msg_div {
            h6 {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                color: var(--primary);
            }

            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                text-align: right;
                color: var(--primary_50);
            }
        }
    }

    .simplebar-wrapper {
        max-height: 50vh !important;
    }
}

.dropdown-menu {
    .simplebar-mask {
        border-radius: 12px !important;
    }

}

.notification_dropdown {
    top: 55px !important;
}

/* SIDEBAR START */
#sidebar-menu {
    .metismenu li a {
        display: flex;

        img {
            margin-right: 10px;
        }

        img.hover_icon {
            display: none;
        }
    }

    .metismenu li.mm-active a,
    .metismenu li:hover a {
        img.hover_icon {
            display: inline;
        }

        img.primary_icon {
            display: none;
        }
    }
}

/* SIDEBAR END */

.login_page {
    background: url('./assets/images/login-bg.webp') center no-repeat;
    background-size: cover;

    .login_form {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 482px;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(5px);

        .login_inner {
            padding: 40px;
            width: 100%;

            .login_content {
                color: #FFFFFF;

                h2 {
                    font-size: 30px;
                    font-weight: 700;
                    line-height: 36px;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19.2px;
                    opacity: 0.5;
                    margin-bottom: 10px;
                }
            }

            label {
                font-size: 16px;
                font-weight: 600;
                line-height: 19.2px;
                color: #FFFFFF;
            }

            input,
            select {
                border-radius: 12px;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                color: var(--primary);
                height: 46px !important;
            }

            .forget_password_link {
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                text-align: right;
                display: block;
                opacity: 0.8;
                margin: 20px auto;
                margin-left: auto;
            }

            button {
                border-radius: 12px;
                font-size: 16px;
                font-weight: 700;
                line-height: 19.2px;
                height: 48px;
            }

            .eye_icon {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                cursor: pointer;
            }
        }
    }
}

.forget_password_inner {
    width: 450px;
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 25px;

    h1 {
        font-size: 32px;
        font-weight: 700;
        line-height: 38.4px;
        color: var(--primary);
    }

    label {
        font-size: 15px;
        font-weight: 600;
        line-height: 18.15px;
        color: var(--primary);
        margin-top: 10px;
    }

    input,
    input:focus {
        background-color: rgba(12, 53, 83, 0.08);
        border: 1px solid rgba(12, 53, 83, 0.08);
        height: 46px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        padding: 20px;
        color: var(--primary);
    }

    button {
        width: 100%;
        background-color: var(--primary);
        color: #FFFFFF;
        border-radius: 12px;
        border: none;
        height: 48px;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.2px;
        text-align: center;
        margin-top: 20px;
    }
}

.dropdown-menu {
    border-radius: 12px !important;

    button {
        color: var(--primary);
        font-size: 13px;
        font-weight: 500;
        line-height: 15.6px;
    }
}

/* LOGIN END */
#sidebar-menu #side-menu .mm-active,
#sidebar-menu #side-menu li:hover {
    background-color: #0C3553;
    border-radius: 10px;
}

.logo_large {
    height: 50px;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
    background-color: #0C3553;
}

.header-item .profile_img {
    background-color: var(--primary_50);
    width: 36px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary);
}

.button_spinner {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
}

/* DASHBOARD START */
.dashboard_top_bar {
    h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: 21.6px;
        color: var(--primary);
    }

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 12px;
        color: var(--primary_50);
    }

    .inner_section {
        border-radius: 10px;
        background: var(--primary_01);
        padding: 15px;
        margin-top: 18px;

        img {
            margin-bottom: 13px;
        }

        h3 {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            color: var(--primary);
        }
    }
}

.sales_dashboard_graph {
    label {
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        color: var(--primary);
    }

    .css-13cymwt-control {
        border-radius: 10px;
    }

    .rs-picker-input-group,
    .rs-picker-input-group input {
        background: var(--primary_01) !important;
    }
}

.marketing_dashboard {
    .hit_details {
        .total_hit_val {
            color: var(--primary);
            font-size: 36px;
            font-weight: 700;
            line-height: 43.2px;
            display: block;
            margin-bottom: 5px;
        }

        .total_hit_label {
            color: var(--primary_50);
            font-size: 24px;
            font-weight: 400;
            line-height: 28.8px;
            margin-bottom: 0;
        }

        .inner_details {
            padding-top: 20px;

            .top_title {
                display: flex;
                justify-content: space-between;
            }

            h2 {
                color: var(--primary);
                font-size: 24px;
                font-weight: 700;
                line-height: 28.8px;
                position: relative;
            }

            h2.circle {
                margin-left: 35px;
            }

            h2.circle::before {
                content: "";
                display: block;
                position: absolute;
                width: 18px;
                height: 18px;
                background-color: #D5B086;
                border-radius: 50%;
                top: 5px;
                left: -35px;
            }

            h2.circle.ios::before {
                background-color: var(--primary);
            }

            h2.circle.website::before {
                background-color: #3CB3C1;
            }

            ul {
                padding-left: 0;
                max-height: 90px;
                overflow-y: auto;
            }


            ul li {
                list-style: none;
                color: var(--primary_50);
                font-size: 24px;
                font-weight: 400;
                line-height: 28.8px;
                display: flex;
                justify-content: space-between;
                margin: 10px 0;
                padding-right: 5px;
            }

            ul {
                scrollbar-width: thin;
                scrollbar-color: var(--main) var(--primary_01);
            }

            ul::-webkit-scrollbar {
                width: 12px;
            }

            ul::-webkit-scrollbar-track {
                background: var(--primary_01);
            }

            ul::-webkit-scrollbar-thumb {
                background-color: var(--main);
                border-radius: 20px;
                border: 3px solid var(--primary_01);
            }
        }
    }
}

/* DASHBOARD END */

.single_banner {
    .fs_18_700 {
        margin-bottom: 15px;
        height: 44px;
    }

    .banner_img {
        /* min-height: 200px !important; */
        border-radius: 8px;
        max-width: 100%;
        width: auto;
        /* height: 300px; */
        height: auto;
        margin: auto;
        display: block;
        object-fit: cover;
        max-width: 100%;
    }
}



@media only screen and (max-width:1440px) {
    .single_banner .banner_img {
        max-height: 180px;
    }
}

@media only screen and (max-width:1600px) {
    .single_banner .banner_img {
        max-height: 215px;
    }
}

@media only screen and (max-width:1920px) {
    .single_banner .banner_img {
        max-height: 275px;
    }
}

.filters_div {

    input,
    input:focus,
    select {
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        color: var(--primary);
        background: var(--primary_01);
        height: 35px;
        border: none;
        border-radius: 8px;
    }

    .select2-selection input {
        height: auto !important;
    }
}

/* DASHBOARD TABLE */
.dashboard_page {
    table td {
        height: 55px !important;
    }

    .nav-tabs-custom .nav-item .nav-link {
        color: var(--primary);
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.5;
        padding: 0;
        padding: 20px 0;
        border-radius: 12px;
    }

    .nav-tabs-custom .nav-item .nav-link.active {
        font-size: 16px;
        font-weight: 700;
        opacity: 1;
    }

}

/* SALES START */
.unit_details {
    .carousel-item img {
        border-radius: 16px;
        width: 100%;
    }

    .unit_details_tab {
        ul {
            padding-left: 0;

            li {
                margin-left: 20px;
            }
        }
    }
}

/* SALES END */

.single_req {
    margin: 10px 0;
}

.single_req>p {
    font-size: 14px;
    color: #0C3553;
    opacity: 0.5;
    margin-bottom: 5px;
}

.single_req>h4 {
    color: var(--primary);
    /* font-size: 20px; */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.upload_label {
    width: 100%;
    height: 72px;
    background: rgba(12, 53, 83, 0.20);
    border-radius: 12px;
    border: 1px dashed rgba(12, 53, 83, 0.20);
    color: var(--primary);
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: capitalize;

    p {
        padding: 0 25px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.fs_18 {
    font-size: 18px !important;
}

.close_btn {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 12px;
    right: 15px;
}

.mdi-close {
    font-size: 24px;

}

.action_buttons button {
    margin: auto 5px;
}

.action_buttons button:first-child {
    margin-left: 0;
}

/* MARKETING  START*/
.projects_page,
.unit_page {
    .status_tag {
        width: 110px;
    }
}

.project_details,
.unit_details {
    .status_tag {
        width: 103px;
        height: 30px;
        line-height: normal;
    }

    .facility_row {
        ul {
            list-style: none;
            margin-bottom: 0;

            li {
                margin: 12px auto;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                color: var(--primary);

                img {
                    margin-right: 15px;
                }
            }
        }
    }

    .video_row video {
        width: 100%;
        height: auto;
        border-radius: 12px;
    }

    .gallery_images_row {
        img.gallery_image {
            width: 100%;
            height: 300px;
            object-fit: cover;

        }
    }

    .nearby_landmarks {
        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;

            li {
                display: flex;
                justify-content: space-between;
                margin-top: 15px;
            }
        }
    }
}

@media only screen and (max-width:1440px) {

    .project_details,
    .unit_details {
        & .gallery_images_row {
            & img.gallery_image {
                width: 100%;
                height: 240px;
            }
        }
    }
}

.app_look_fill_row {
    .color_inner_div {
        border-radius: 12px;
        border: 1px solid var(--primary_01);
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primary_0_05);
    }

    .color_picker_div {
        width: calc(100% - 90px);
        height: 46px;
        border-radius: 12px;
        cursor: pointer;
        border: none;
    }

    .maintenance_mode {
        hr {
            margin-bottom: 14px;
        }

        label {
            margin-bottom: 0 !important;
        }
    }
}

.update_project .nav-tabs-custom {
    padding-bottom: 15px;

    .nav-item .nav-link::after {
        bottom: -18px;
    }
}

.project_general_details .feature_image {
    img {
        border-radius: 16px;
    }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background: var(--primary);
    border-radius: 50%;
    padding: 15px;
    background-size: 20px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.product_gallery_images {
    .single_gallery {
        margin: 10px auto;

        img.gallery_image {
            width: 100%;
            /* max-height: 200px; */
            /* height: 113px; */
            border-radius: 10px;
            object-fit: cover;
        }

        .trash_icon {
            position: absolute;
            top: 20px;
            right: 20px;
            background-color: #FFFFFF;
            width: 30px;
            height: 30px;
            padding: 8px;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .file_label {
        height: calc(100% - 20px);
        margin-top: 8px;
        color: var(--primary);
        font-weight: 700;
        min-height: 113px;

        p {
            padding: 0 10px;
            color: red;
        }
    }
}

.file_label {
    p {
        padding: 0 25px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/* MARKETING  END*/

/* CUSTOMER SUPPORT */
.ticket_over_time_chart {
    canvas {
        /* width: 80% !important; */
        height: 400px !important;
    }
}

.custom_nav_2 {
    .nav-item {
        a {
            width: 176px;
            font-size: 16px !important;
            font-weight: 500;
            line-height: 19.2px;
            color: var(--primary_50) !important;
            text-align: center;
            background-color: #FFFFFF !important;
            border-radius: 8px;
            margin-right: 15px;
            padding: 15px;
        }

        a.active {
            color: #FFFFFF !important;
            background-color: var(--primary) !important;
        }
    }
}

.table_corner_radius {
    th:first-child {
        border-top-left-radius: 12px;
    }

    th:last-child {
        border-top-right-radius: 12px;
    }
}

.sidebar_logout_link {
    position: absolute;
    bottom: 80px;
    margin-left: 10px;
    display: flex;

    span {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.2px;

    }

    img {
        margin-left: 25px;
    }
}

.header_links {
    a {
        font-size: 13px;
        font-weight: 500;
        line-height: 15.6px;
        color: var(--primary);
    }
}

.history_details {
    hr {
        margin-top: 1.5rem;
    }
}

.primary_bg_10 {
    background: var(--primary_0_05);
    padding: 15px;
    border-radius: 12px;
    margin: 20px 0;
}

.admin_duration_section {
    .nav-link {
        background-color: var(--primary_01);
        margin: 5px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.36px;
        border-radius: 8px;
    }

    .nav-link.active {
        color: #FFFFFF !important;
    }
}

/* .add_coupon_section .rs-picker,
.full_rs_picker .rs-picker { */
.rs-picker {
    width: 100%;
}

.danger_color_border .rs-input-group {
    border: 1px solid #f26868 !important;
}

.admin_notifications {
    tr.unread {
        background: var(--read);

        td,
        th {
            background: var(--read);
        }
    }
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: unset !important;
}

.ql-editor {
    height: 500px !important;
}